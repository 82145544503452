import React, { useEffect, useState } from 'react'
import { Close, InfoOutlined } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { slide as SlideMenu } from 'react-burger-menu'
import { Popover, Tooltip, useMediaQuery } from '@mui/material'
import slugify from 'slugify'
import WebFont from '../webfontloader'
import flockColors from '../flockColors/flockColors'
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '../constants/constants'
import Typography from '../Typography/Typography'
import TrackingContextProvider from '../TrackingContextProvider/TrackingContextProvider'
import useTracking, { TrackingConfig } from '../useTracking/useTracking'

WebFont.load({
  google: {
    families: ['Lato:300,400,bold&display=swap'],
  },
})

const HeaderContainer = styled('div')({
  width: '100%',
  height: '4rem',
  position: 'sticky',
  marginBottom: '-4rem',
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 999,
  transition: 'background-color 0.5s ease',
  backgroundColor: flockColors.white,
})

const InnerContainer = styled('div')({
  maxWidth: '71.875rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const Menu = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  height: '100%',
  flex: 1,
})

const TextLogoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '2.5rem',

  [TABLET_BREAKPOINT]: {
    paddingLeft: '1rem',
  },
})

const TextLogo = styled('span')({
  fontSize: '1.875rem',
  fontFamily: 'Lato',
  letterSpacing: '0.625rem',
  textDecoration: 'none',
  fontWeight: 400,
  color: flockColors.black,
  opacity: 0.95,
  transition: 'opacity 0.5s ease',
  cursor: 'pointer',

  '&:hover': {
    opacity: 1,
  },

  [MOBILE_BREAKPOINT]: {
    fontSize: '1.5rem',
    color: 'black!important',
  },
})

const CobrandingLogo = styled('span')({
  fontSize: '1.5rem',
  fontFamily: 'Lato',
  textDecoration: 'none',
  fontWeight: 400,
  color: flockColors.black,
  opacity: 0.95,
  transition: 'opacity 0.5s ease',
  cursor: 'pointer',

  '&:hover': {
    opacity: 1,
  },

  [TABLET_BREAKPOINT]: {
    fontSize: '1rem',
  },

  [MOBILE_BREAKPOINT]: {
    color: 'black!important',
  },
})

const CobrandingFlockLogo = styled('span')({
  fontSize: '1.5rem',
  fontFamily: 'Lato',
  letterSpacing: '0.625rem',
  textDecoration: 'none',
  fontWeight: 400,
  color: flockColors.black,
  opacity: 0.95,
  transition: 'opacity 0.5s ease',
  cursor: 'pointer',

  '&:hover': {
    opacity: 1,
  },

  [TABLET_BREAKPOINT]: {
    fontSize: '1rem',
  },

  [MOBILE_BREAKPOINT]: {
    color: 'black!important',
  },
})

const PartnerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
})

const PartnerSubtitle = styled(Typography)({
  width: '100%',
  fontSize: '0.8rem',
  display: 'flex',
  alignItems: 'center',

  [TABLET_BREAKPOINT]: {
    fontSize: '0.7rem',
  },

  [MOBILE_BREAKPOINT]: {
    color: 'black!important',
  },
})

const StyledInfo = styled(InfoOutlined)({
  cursor: 'pointer',
  width: '0.85rem',
  height: '0.85rem',
  marginLeft: '0.2rem',
  marginBottom: '0.15rem',
})

const StyledTooltip = styled((props: any) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: white;
    color: black;
    border-radius: 0;
    max-width: 12rem;
    line-height: 150%;
  }

  & .MuiTooltip-arrow::before {
    color: white;
  }
`

const Divider = styled('div')({
  height: '2.5rem',
  width: '2px',
  marginLeft: '1rem',
  marginRight: '1rem',

  [MOBILE_BREAKPOINT]: {
    backgroundColor: 'black!important',
  },
})

const ImageLogoWrapper = styled('div')({
  width: '3rem',
  height: '3rem',
  borderRadius: '10rem',
  marginRight: '1rem',
  overflow: 'hidden',
  cursor: 'pointer',

  [TABLET_BREAKPOINT]: {
    display: 'none',
  },
})

const ImageLogo = styled('img')({
  width: '3rem',
  height: 'auto',

  [TABLET_BREAKPOINT]: {
    display: 'none',
  },
})

const MenuItemsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '100%',
  flex: 1,
  paddingRight: '2rem',

  [TABLET_BREAKPOINT]: {
    paddingRight: '1rem',
  },

  [MOBILE_BREAKPOINT]: {
    display: 'none',
    paddingRight: 'unset',
  },
})

const MenuItemContainer = styled('a')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: flockColors.black,
  cursor: 'pointer',
  justifyContent: 'center',
  transition: 'opacity 0.5s ease',
  opacity: 0.95,

  '&:hover': {
    opacity: 1,
  },

  [MOBILE_BREAKPOINT]: {
    height: 'unset',
    paddingBottom: '1rem',
    paddingTop: '1rem',
  },
})

const MenuItemLink = styled('span')({
  fontSize: '1rem',
  fontFamily: 'Lato',
  marginLeft: '1rem',
  marginRight: '1rem',

  [TABLET_BREAKPOINT]: {
    marginLeft: '0.75rem',
    marginRight: '0.75rem',
  },

  [MOBILE_BREAKPOINT]: {
    fontSize: '1.25rem',
    textAlign: 'center',
    width: '100%',
  },
})

const SubmenuPopover = styled(Popover)({
  marginTop: '0.5rem',
})

const SubmenuWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const SubmenuItemLink = styled('a')({
  margin: '0.5rem',
  color: flockColors.blue,
  opacity: 0.9,
  textDecoration: 'unset',
  transition: 'color 1s ease',
  '&:hover': {
    opacity: 1,
  },
})

type MenuItemProps = {
  text: string
  href?: string
  onClick?: () => void
  trackingConfig?: TrackingConfig
  submenuOptions?: SubmenuItemConfig[]
  color?: string
  selected?: boolean
  selectable?: boolean
}

type SubmenuItemConfig = {
  text: string
  mobileText: string
  href: string
  trackingConfig?: TrackingConfig
  onClick?: () => void
}

const MenuItem = (props: MenuItemProps) => {
  const {
    text,
    href,
    onClick,
    submenuOptions,
    trackingConfig,
    selectable,
    selected,
    color,
  } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | null>(null)

  const { track } = useTracking()

  const trackingName =
    trackingConfig?.name || slugify(text?.toLowerCase() || '')

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    track(trackingName, trackingConfig?.properties)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const isMobile = useMediaQuery(MOBILE_BREAKPOINT)

  let extraStyles: any = { color }
  if (selectable) {
    extraStyles = {
      color,
      position: 'relative',
      opacity: 1,
      transition: `opacity 0.3s ease`,
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: selected
          ? `radial-gradient(ellipse at bottom, ${flockColors.blue} 2%, white 40%)`
          : 'inherit',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        transition: `opacity 0.3s ease`,
        opacity: selected ? 1 : 0,
        zIndex: -1,
      },
    }
  }

  if (submenuOptions) {
    if (isMobile) {
      return (
        <>
          {submenuOptions.map((option) => (
            <MenuItemContainer
              key={option.text}
              href={option.href}
              onClick={(e: React.MouseEvent) => {
                if (option.onClick) {
                  option.onClick()
                  const itemTrackingName =
                    option.trackingConfig?.name ||
                    slugify(option.text?.toLowerCase() || '')
                  track(itemTrackingName, option.trackingConfig?.properties)
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              sx={extraStyles}
            >
              <MenuItemLink>{option.mobileText}</MenuItemLink>
            </MenuItemContainer>
          ))}
        </>
      )
    } else {
      return (
        <>
          <MenuItemContainer
            href="#"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              handleClick(e)
              e.preventDefault()
              e.stopPropagation()
            }}
            sx={extraStyles}
            data-cy={`${text.toLowerCase().replace(' ', '-')}-nav`}
          >
            <MenuItemLink>{text}</MenuItemLink>
          </MenuItemContainer>
          <SubmenuPopover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            disableScrollLock
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <SubmenuWrapper>
              {submenuOptions.map((option) => (
                <SubmenuItemLink
                  key={option.text}
                  href={option.href || ''}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                    if (option.onClick) {
                      option.onClick()
                      const itemTrackingName =
                        option.trackingConfig?.name ||
                        slugify(option.text?.toLowerCase() || '')
                      track(itemTrackingName, option.trackingConfig?.properties)
                      e.preventDefault()
                      e.stopPropagation()
                    }
                  }}
                >
                  {option.text}
                </SubmenuItemLink>
              ))}
            </SubmenuWrapper>
          </SubmenuPopover>
        </>
      )
    }
  } else {
    return (
      <MenuItemContainer
        href={href}
        onClick={(e: React.MouseEvent) => {
          if (onClick) {
            onClick()
          }
          track(trackingName, trackingConfig?.properties)
          e.preventDefault()
          e.stopPropagation()
        }}
        sx={extraStyles}
        data-cy={`${text.toLowerCase().replace(' ', '-')}-nav`}
      >
        <MenuItemLink>{text}</MenuItemLink>
      </MenuItemContainer>
    )
  }
}

MenuItem.defaultProps = {
  submenuItemConfig: undefined,
  selected: false,
  selectable: false,
  color: undefined,
  onClick: undefined,
  href: undefined,
}

const BurgerWrapper = styled('div')({
  display: 'none',
  [MOBILE_BREAKPOINT]: {
    display: 'block',
  },
})

const burgerStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '1.563rem',
    height: '1.25rem',
    right: '1.5rem',
    top: '1.45rem',
  },
  bmBurgerBars: {
    background: flockColors.black,
  },
  bmBurgerBarsHover: {
    background: flockColors.black,
  },
  bmCrossButton: {
    height: '3rem',
    width: '3rem',
    color: flockColors.black,
  },
  bmCross: {},
  bmMenuWrap: {
    position: 'fixed',
    width: '100%',
    height: '100%',
  },
  bmMenu: {
    background: flockColors.white,
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
    overflow: 'hidden',
  },
  bmMorphShape: {
    fill: flockColors.black,
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
}

const BurgerTitle = styled('span')({
  fontSize: '1.875rem',
  fontFamily: 'Lato',
  letterSpacing: '0.625rem',
  textDecoration: 'none',
  fontWeight: 400,
  color: flockColors.black,
  transition: 'color 0.5s ease',
  textAlign: 'center',
  width: '100%',
  paddingBottom: '2rem',
  outline: 'none',
})

export type HeaderProps = {
  onLogoClick: () => void
  selectable?: boolean
  transparentTop?: boolean
  secondaryHeaderText?: string
  menuItemConfigs?: MenuItemProps[]
  trackingConfig?: TrackingConfig
  cobrandingConfig?: {
    fullName: string
    websiteUrl: string
    imageUrl: string
  }
}

const Header = (props: HeaderProps) => {
  const {
    onLogoClick,
    selectable,
    menuItemConfigs,
    transparentTop,
    secondaryHeaderText,
    cobrandingConfig,
    trackingConfig,
  } = props

  const { track } = useTracking()

  const trackingName = trackingConfig?.name || 'header'

  const trackedLogoClick = () => {
    onLogoClick()
    track(`${trackingConfig?.name}-logo-clicked`, trackingConfig?.properties)
  }

  const visitCobrandingWebsite = () => {
    if (cobrandingConfig?.websiteUrl) {
      window.open(cobrandingConfig?.websiteUrl, '_blank')
    } else {
      trackedLogoClick()
    }
  }

  const [transparent, setTransparent] = useState(transparentTop)

  useEffect(() => {
    const listenerFn = () => {
      setTransparent(window.scrollY < 50)
    }
    if (typeof window !== 'undefined' && transparentTop) {
      window.addEventListener('scroll', listenerFn)
    } else {
      setTransparent(false)
    }

    return () => {
      window.removeEventListener('scroll', listenerFn)
    }
  }, [transparentTop])

  return (
    <TrackingContextProvider
      name={trackingName}
      initialTrackingProperties={trackingConfig?.properties}
    >
      <HeaderContainer
        sx={{ backgroundColor: transparent ? 'unset' : 'white' }}
      >
        <InnerContainer>
          <TextLogoContainer>
            {cobrandingConfig ? (
              <>
                {cobrandingConfig.imageUrl && (
                  <ImageLogoWrapper>
                    <ImageLogo
                      src={cobrandingConfig.imageUrl}
                      onClick={visitCobrandingWebsite}
                    />
                  </ImageLogoWrapper>
                )}
                <CobrandingLogo
                  onClick={visitCobrandingWebsite}
                  sx={{ color: transparent ? 'white' : 'black' }}
                >
                  {cobrandingConfig.fullName}
                </CobrandingLogo>
                <Divider
                  sx={{ backgroundColor: transparent ? 'white' : 'black' }}
                />
                <PartnerWrapper>
                  <CobrandingFlockLogo
                    sx={{ color: transparent ? 'white' : 'black' }}
                  >
                    FLOCK
                  </CobrandingFlockLogo>
                  <PartnerSubtitle
                    sx={{ color: transparent ? 'white' : 'black' }}
                  >
                    PARTNER AGENT
                    <StyledTooltip
                      title="Partner Agents are not employed by Flock Homes, Inc. or any of its affiliates."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <StyledInfo />
                    </StyledTooltip>
                  </PartnerSubtitle>
                </PartnerWrapper>
              </>
            ) : (
              <>
                <TextLogo
                  onClick={onLogoClick}
                  sx={{ color: transparent ? 'white' : 'black' }}
                >
                  FLOCK
                </TextLogo>
                {secondaryHeaderText && (
                  <>
                    <Divider
                      sx={{ backgroundColor: transparent ? 'white' : 'black' }}
                    />
                    <CobrandingLogo
                      sx={{ color: transparent ? 'white' : 'black' }}
                    >
                      {secondaryHeaderText}
                    </CobrandingLogo>
                  </>
                )}
              </>
            )}
          </TextLogoContainer>
          <Menu>
            <MenuItemsWrapper>
              {menuItemConfigs?.map((item) => (
                <MenuItem
                  key={item.text}
                  {...item}
                  color={transparent ? 'white' : 'black'}
                  selectable={selectable || false}
                />
              ))}
            </MenuItemsWrapper>
          </Menu>
        </InnerContainer>
      </HeaderContainer>
      <BurgerWrapper>
        {/** @ts-ignore */}
        <SlideMenu right styles={burgerStyles} customCrossIcon={<Close />}>
          <BurgerTitle onClick={onLogoClick}>FLOCK</BurgerTitle>
          {menuItemConfigs?.map((item) => (
            <MenuItem key={item.text} {...item} />
          ))}
        </SlideMenu>
      </BurgerWrapper>
    </TrackingContextProvider>
  )
}

Header.defaultProps = {
  selectable: false,
  secondaryHeaderText: '',
  menuItemConfigs: [],
  transparentTop: false,
  opacity: 1,
  cobrandingConfig: undefined,
}

export default Header
