import React from 'react'
import { styled } from '@mui/material/styles'
import slugify from 'slugify'
import WebFont from '../webfontloader'
import flockColors from '../flockColors/flockColors'
import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  FLOCK_EMAIL,
  FLOCK_HOMEPAGE_URL,
  FLOCK_BLOG_URL,
} from '../constants/constants'

import EqualHousing from '../images/equal-housing.webp'

import Typography from '../Typography/Typography'
import OpacityLink from '../OpacityLink/OpacityLink'
import TrackingContextProvider from '../TrackingContextProvider/TrackingContextProvider'

WebFont.load({
  google: {
    families: ['Lato:300,400,bold&display=swap'],
  },
})

const Container = styled('div')({
  backgroundColor: flockColors.white,
  fontFamily: 'Inter',
  [TABLET_BREAKPOINT]: {
    marginLeft: '2rem',
    marginRight: '2rem',
  },
})

const InnerContainer = styled('div')({
  padding: '5rem',
  paddingTop: '4rem',
  paddingLeft: 0,
  paddingRight: 0,
  display: 'flex',
  justifyContent: 'space-between',

  [MOBILE_BREAKPOINT]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    padding: '2rem',
    paddingTop: '4rem',
  },
})

const PrivacyContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '65%',

  [MOBILE_BREAKPOINT]: {
    alignItems: 'center',
  },

  [MOBILE_BREAKPOINT]: {
    maxWidth: '100%',
  },
})

const PrivacyLinks = styled('div')({
  paddingTop: '1rem',
  paddingBottom: '1rem',

  [MOBILE_BREAKPOINT]: {
    textAlign: 'center',
  },
})

const PrivacyLink = styled(OpacityLink)({
  paddingRight: '4rem',
  color: flockColors.black,
  fontSize: '0.95rem',

  [TABLET_BREAKPOINT]: {
    fontSize: '0.8rem',
  },

  [MOBILE_BREAKPOINT]: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
})

const Copyright = styled('div')({
  color: flockColors.black,
  fontSize: '0.95rem',

  [TABLET_BREAKPOINT]: {
    fontSize: '0.8rem',
  },

  [MOBILE_BREAKPOINT]: {
    textAlign: 'center',
  },
})

const Disclaimer = styled(Typography)({
  color: flockColors.black,
  fontSize: '0.75rem',

  [TABLET_BREAKPOINT]: {
    fontSize: '0.75rem',
  },

  [MOBILE_BREAKPOINT]: {
    textAlign: 'center',
  },
})

const Nav = styled('div')({
  display: 'flex',

  [MOBILE_BREAKPOINT]: {
    flexDirection: 'column',
  },
})

const NavCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '1rem',
  marginRight: '1rem',

  [MOBILE_BREAKPOINT]: {
    alignItems: 'center',
    paddingBottom: '2rem',
  },
})

const StyledLink = styled(OpacityLink)({
  marginBottom: '1.25rem',
  color: flockColors.blue,
})

const EqualHousingImage = styled('img')({
  marginTop: '1rem',
  width: '3rem',
  height: '3rem',

  [MOBILE_BREAKPOINT]: {
    margin: 'auto',
    marginTop: '2rem',
  },
})

type FooterLink = {
  label: string
  url: string
}

type FooterProps = {
  DisclaimerComponent?: React.ReactNode
  footerLinks?: FooterLink[][]
  termsUrl?: string
  privacyUrl?: string
}

const Footer = (props: FooterProps) => {
  const { DisclaimerComponent, footerLinks, termsUrl, privacyUrl } = props
  return (
    <TrackingContextProvider name="footer">
      <Container>
        <InnerContainer>
          <PrivacyContainer>
            <Copyright>Copyright 2023 Flock Homes, Inc.</Copyright>
            <EqualHousingImage
              src={EqualHousing}
              alt="Equal Housing Opportunity"
            />
            <PrivacyLinks>
              <PrivacyLink
                href={termsUrl || `${FLOCK_HOMEPAGE_URL}/terms-of-use`}
                trackingConfig={{ name: 'terms-of-use' }}
              >
                Terms of use
              </PrivacyLink>
              <PrivacyLink
                href={privacyUrl || `${FLOCK_HOMEPAGE_URL}/privacy`}
                trackingConfig={{ name: 'privacy' }}
              >
                Privacy
              </PrivacyLink>
            </PrivacyLinks>
            <Disclaimer>
              {DisclaimerComponent ? (
                <>{DisclaimerComponent}</>
              ) : (
                <>
                  Disclaimer: All content presented here is purely
                  informational. It is intended for information gathering only.
                  The information presented here is not an offer to buy or sell
                  securities or a solicitation of any offer to buy or sell
                  securities. It is not personalized investment advice, nor
                  should it be construed as financial, legal, or tax advice. A
                  professional financial advisor, attorney, and/or tax
                  professional should be consulted regarding your specific
                  financial, legal, and/or tax situation.
                </>
              )}
            </Disclaimer>
          </PrivacyContainer>
          <Nav>
            {footerLinks?.map((linkSet) => (
              <NavCol key={`${linkSet[0].label}-key`}>
                {linkSet.map((link) => (
                  <StyledLink
                    key={link.label}
                    href={link.url}
                    trackingConfig={{ name: slugify(link.label.toLowerCase()) }}
                  >
                    {link.label}
                  </StyledLink>
                ))}
              </NavCol>
            ))}
          </Nav>
        </InnerContainer>
      </Container>
    </TrackingContextProvider>
  )
}

Footer.defaultProps = {
  DisclaimerComponent: null,
  footerLinks: [
    [
      {
        label: 'About us',
        url: `${FLOCK_HOMEPAGE_URL}/about-us`,
      },
      {
        label: 'FAQ',
        url: `${FLOCK_HOMEPAGE_URL}/faq`,
      },
      {
        label: 'Contact',
        url: `mailto:${FLOCK_EMAIL}`,
      },
      {
        label: 'Blog',
        url: FLOCK_BLOG_URL,
      },
    ],
    [
      {
        label: 'How it works',
        url: `${FLOCK_HOMEPAGE_URL}/how-it-works`,
      },
      {
        label: 'Careers',
        url: `${FLOCK_HOMEPAGE_URL}/careers`,
      },
      {
        label: 'Our partners',
        url: `${FLOCK_HOMEPAGE_URL}/about-us#partners`,
      },
    ],
  ],
  termsUrl: '',
  privacyUrl: '',
}

export default Footer
