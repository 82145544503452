import React, { useCallback, useMemo, useState } from 'react'
import { flattenObject } from '@flock/utils'
import useTracking, {
  TrackingNode,
  TrackingProperties,
  TrackingContext,
} from '../useTracking/useTracking'
import { useGlobalTracking } from '../useGlobalTracking/useGlobalTracking'

type TrackingContextProviderProps = {
  name: string
  initialTrackingProperties?: {
    [key: string]: any
  }
  children: React.ReactNode
}

const TrackingContextProvider = ({
  name,
  initialTrackingProperties,
  children,
}: TrackingContextProviderProps) => {
  const [trackingProperties, setTrackingProperties] = useState(
    initialTrackingProperties || {}
  )
  const globalTrackingContext = useGlobalTracking()
  const trackFn = globalTrackingContext.trackFn || (() => {})
  const trackPageFn = globalTrackingContext.trackPageFn || (() => {})

  // Check the outer context for a provider. If there is one, this is a nested
  // context provider and we need to combine the tracking context with the outer context.
  const parentContext = useTracking()
  const trackingNode = useMemo(() => {
    const node: TrackingNode = {
      name,
      ...trackingProperties,
    }

    if (parentContext?.trackingNode) {
      node.parent = parentContext.trackingNode
    }

    return node
  }, [parentContext?.trackingNode, name, initialTrackingProperties])

  // For each tracking function, optionally provide an eventName to be able to
  // specify the component that is firing the event as well as additional properties
  // related to the event.
  const trackPage = useCallback(
    (pageName: string, properties: TrackingProperties = {}) => {
      if (trackPageFn) {
        const pageNode = {
          ...properties,
          parent: trackingNode,
        }
        const flattenedProperties = flattenObject(pageNode)
        trackPageFn(pageName, flattenedProperties)
      }
    },
    [trackFn, trackingNode]
  )

  const track = useCallback(
    (eventName: string, properties: TrackingProperties = {}) => {
      if (trackFn) {
        const eventNode = {
          ...properties,
          parent: trackingNode,
        }
        const flattenedProperties = flattenObject(eventNode)
        trackFn(eventName, flattenedProperties)
      }
    },
    [trackFn, trackingNode]
  )

  if (!track || !trackPage) {
    return <>children</>
  }

  return (
    <TrackingContext.Provider
      value={{
        trackingNode,
        updateTrackingProperties: (properties: TrackingProperties) =>
          setTrackingProperties(properties),
        track,
        trackPage,
      }}
    >
      {children}
    </TrackingContext.Provider>
  )
}

export default TrackingContextProvider

TrackingContextProvider.defaultProps = {
  initialTrackingProperties: null,
}
