import React from 'react'
import MuiTypography, { TypographyProps } from '@mui/material/Typography'
import { ThemeProvider } from '@mui/material/styles'
import WebFont from '../webfontloader'
import flockTheme from '../flockTheme/flockTheme'

WebFont.load({
  google: {
    families: ['Inter:300,normal,500,bold,900&display=swap'],
  },
})

const Typography = (props: TypographyProps) => (
  <ThemeProvider theme={flockTheme}>
    <MuiTypography {...props} />
  </ThemeProvider>
)

export default Typography
