import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  flockColors,
  Button,
  Typography,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '@flock/flock-component-library'
import { colors } from '../theme'

export const StyledTextField = styled(TextField)({
  '& > div > input': {
    width: '24rem',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
    fontFamily: 'Inter',
  },
  '& > div > textarea': {
    width: '24rem',
    paddingBottom: '0.75rem',
  },

  '& > div > input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },

  '& > div > input::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },

  '& > p': {
    marginLeft: 0,
    fontFamily: 'Inter',
  },

  [MOBILE_BREAKPOINT]: {
    width: '100%',
  },
})

export const TextFieldWrapper = styled('div')({
  paddingBottom: '1rem',

  [MOBILE_BREAKPOINT]: {
    width: '100%',
  },
})

export const FieldLabel = styled('div')({
  fontWeight: 'bold',
  paddingBottom: '0.5rem',
})

export const CtaWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '1rem',
  flexDirection: 'column',
})

export const CtaButton = styled(Button)({
  fontSize: '1rem',
  fontWeight: 'bold',
  fontFamily: 'Inter!important',
  textTransform: 'none',
  backgroundColor: flockColors.blue,
  color: colors.white,
  width: '14rem',
  height: '3rem',
  marginLeft: '1rem',
  marginRight: '1rem',

  '&:hover': {
    backgroundColor: flockColors.hoverBlue,
  },

  [MOBILE_BREAKPOINT]: {
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    marginBottom: '1rem',
  },
})

export const SecondaryButton = styled(CtaButton)({
  backgroundColor: colors.lightGray,
  color: colors.midGray,
  border: `2px solid ${colors.midGray}`,
  '&:hover': {
    backgroundColor: colors.white,
  },
})

export const TertiaryButton = styled(CtaButton)({
  backgroundColor: `${flockColors.lightGray}20`,
  color: flockColors.blue,
  border: `2px solid ${flockColors.blue}`,
  '&:hover': {
    backgroundColor: flockColors.white,
    border: `2px solid ${colors.hoverBlue}`,
  },
})

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '5rem',
  margin: 'auto',
  maxWidth: '47.5rem',
  width: '100%',

  [MOBILE_BREAKPOINT]: {
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
    width: 'unset',
    minHeight: 'calc(100vh - 10rem)',
  },
})

export const Question = styled('h1')({
  marginBottom: '1rem',
  textAlign: 'center',
  fontFamily: 'Inter',

  [MOBILE_BREAKPOINT]: {
    textAlign: 'left',
    fontSize: '1.5rem',
    marginRight: '0.5rem',
    marginTop: '2rem',
    marginBottom: '1rem',
    width: '100%',
  },
})

export const ButtonsWrapper = styled('div')({})

export const PrimaryButton = styled(Button)({
  backgroundColor: flockColors.blue,
  borderRadius: '5px',
  '& > .MuiButton-label': {
    color: colors.white,
    textTransform: 'none',
    fontSize: '1rem',
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    fontWeight: 'bold',

    [MOBILE_BREAKPOINT]: {
      fontSize: '1rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },

  '&:hover': {
    backgroundColor: flockColors.hoverBlue,
  },
})

export const TanButton = styled(PrimaryButton)({
  backgroundColor: colors.tan,

  '& > .MuiButton-label': {
    color: colors.darkBlue,
  },

  '&:hover': {
    backgroundColor: colors.hoverTan,
  },
})

export const OutlineButton = styled(PrimaryButton)({
  backgroundColor: colors.transparent,
  border: `1px solid ${flockColors.blue}`,

  '& > .MuiButton-label': {
    color: flockColors.blue,
  },

  '&:hover': {
    backgroundColor: colors.transparent,
    color: flockColors.hoverBlue,
  },
})

export const FooterWrapper = styled('div')({
  maxWidth: '65.875rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',
})

export const ThinDividerDark = styled('div')({
  width: '5rem',
  height: '0.2rem',
  margin: 'auto',
  backgroundColor: flockColors.darkBlue,
})

export const ThinDividerLight = styled(ThinDividerDark)({
  backgroundColor: flockColors.white,
})

export const SectionTitle = styled(Typography)({
  color: colors.darkBlue,
  maxWidth: '45rem',
  paddingBottom: '1rem',
  lineHeight: '3rem',

  [TABLET_BREAKPOINT]: {
    fontSize: '2rem',
    margin: '1rem',
    paddingBottom: 0,
  },

  [MOBILE_BREAKPOINT]: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'left',
    marginLeft: 0,
  },
})
