import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { SxProps } from '@mui/system'
import { styled } from '@mui/material/styles'
import useTracking, { TrackingProperties } from '../useTracking/useTracking'
import TrackingContextProvider from '../TrackingContextProvider/TrackingContextProvider'

export type PageLayoutProps = {
  title: string
  metaTags?: any[]
  trackingName?: string
  trackingProperties?: TrackingProperties
  children: React.ReactNode
  sx?: SxProps
}

const Wrapper = styled('div')({})

const defaultMetaTags = [
  {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1, maximum-scale=5',
    'data-react-helmet': true,
  },
  {
    name: 'format-detection',
    content: 'telephone=no',
  },
]

const PageLayout = ({
  title,
  metaTags,
  trackingName,
  trackingProperties,
  children,
  sx,
}: PageLayoutProps) => {
  const [mounted, setMounted] = useState(false)
  const [trackName, setTrackName] = useState(trackingName)
  const { trackPage } = useTracking()

  let name = trackingName
  if (!trackingName && typeof window !== 'undefined') {
    name = window.location.pathname
    if (name !== '/') {
      name = name.replace('/', '')
    }
  }

  useEffect(() => {
    document.body.style.margin = '0'
    document.body.style.fontFamily = 'Inter'
    setMounted(true)

    if (name) {
      setTrackName(name)
      trackPage(name)
    }
  }, [name])

  if (!metaTags) {
    defaultMetaTags.push({
      name: 'description',
      content:
        'Defer capital gains taxes, maintain income and keep your tenants in place with Flock’s rental property exchange options. Seamlessly exchange your rental property for shares in a portfolio of homes managed by Flock.',
      'data-react-helmet': true,
    })
  }

  return (
    <TrackingContextProvider
      name={trackName as string}
      initialTrackingProperties={trackingProperties}
    >
      <Wrapper sx={sx}>
        {mounted && (
          <Helmet
            htmlAttributes={{
              lang: 'en',
            }}
            title={title}
            meta={
              metaTags ? [...defaultMetaTags, ...metaTags] : defaultMetaTags
            }
          />
        )}
        {children}
      </Wrapper>
    </TrackingContextProvider>
  )
}

PageLayout.defaultProps = {
  metaTags: undefined,
}

export default PageLayout
