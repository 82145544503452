import { styled } from '@mui/material/styles'
import React, { useEffect } from 'react'
import { Header, Footer, PageLayout } from '@flock/flock-component-library'
import { useTracking } from '@flock/shared-ui'

import { colors } from '../../theme'
import { TABLET_BREAKPOINT } from '../../components/styleConstants'
import { landingHeaderConfigs, onLogoClick } from '../../components/utils'
import { FooterWrapper } from '../../components/SharedStyles'

const Container = styled('div')({
  padding: '5rem',
  paddingTop: '10rem',
  fontSize: '1.3rem',
  lineHeight: '3.7rem',

  [TABLET_BREAKPOINT]: {
    padding: '2rem',
    paddingTop: '4rem',
    lineHeight: '2.5rem',
  },
})

const Title = styled('h1')({
  color: colors.darkBlue,
  fontSize: '2rem',
})

const Section = styled('section')({
  paddingBottom: '5rem',
  [TABLET_BREAKPOINT]: {
    fontSize: '1rem',
  },
})

const Bold = styled('span')({
  fontWeight: 'bold',
})

const Paragraph = styled('p')({
  margin: 'unset',
})

const List = styled('ul')({})

const ListItem = styled('li')({})

const PrivacyPolicy = () => {
  const { trackPage } = useTracking()
  useEffect(() => {
    trackPage('privacy-10-06-2020', {
      category: 'landing',
    })
  }, [trackPage])

  return (
    <PageLayout title="Privacy policy - FLOCK">
      <Header
        onLogoClick={onLogoClick}
        menuItemConfigs={landingHeaderConfigs}
      />
      <Container>
        <Title>FLOCK HOMES, INC.</Title>
        <Title>PRIVACY POLICY</Title>
        <Section>
          <h4>Last Updated: October 6, 2020</h4>
          This privacy policy (<Bold>“Policy”</Bold>) describes how Flock Homes,
          Inc. and its related companies (<Bold>“Company“</Bold>) collect, use
          and share personal information of consumer users of this website,
          www.flockhomes.com (the <Bold>“Site“</Bold>). This Policy also applies
          to any of our other websites that post this Policy. This Policy does
          not apply to websites that post different statements.
        </Section>
        <Section>
          <h4>WHAT WE COLLECT</h4>
          <Paragraph>
            We get information about you in a range of ways.
          </Paragraph>
          <Paragraph>
            <Bold>Information You Give Us.</Bold> We collect your‎ name, email
            address, phone number as well as other information you directly give
            us on our Site.
          </Paragraph>
          <Paragraph>
            <Bold>Information We Get From Others.</Bold> We may get information
            about you from other sources. We may add this to information we get
            from this Site.
          </Paragraph>
          <Paragraph>
            <Bold>Information Automatically Collected.</Bold> We automatically
            log information about you and your computer. For example, when
            visiting our Site, we log your computer operating system type,
            browser type, browser language, the website you visited before
            browsing to our Site, pages you viewed, how long you spent on a
            page, access times and information about your use of and actions on
            our Site.
          </Paragraph>
          <Paragraph>
            <Bold>Cookies.</Bold> We may log information using “cookies.”
            Cookies are small data files stored on your hard drive by a website.
            We may use both session Cookies (which expire once you close your
            web browser) and persistent Cookies (which stay on your computer
            until you delete them) to provide you with a more personal and
            interactive experience on our Site. This type of information is
            collected to make the Site more useful to you and to tailor the
            experience with us to meet your special interests and needs.
          </Paragraph>
        </Section>

        <Section>
          <h4>USE OF PERSONAL INFORMATION</h4>
          <Paragraph>We use your personal information as follows</Paragraph>
          <List>
            <ListItem>
              We use your personal information to respond to comments and
              questions and provide customer service.
            </ListItem>
            <ListItem>
              We use your personal information to communicate about promotions,
              upcoming events, and other news about products and services
              offered by us and our selected partners.
            </ListItem>
          </List>
        </Section>

        <Section>
          <h4>SHARING OF PERSONAL INFORMATION</h4>
          <Paragraph>We may share personal information as follows:</Paragraph>
          <List>
            <ListItem>
              We may share personal information with your consent. For example,
              you may let us share personal information with others for their
              own marketing uses. Those uses will be subject to their privacy
              policies.
            </ListItem>
            <ListItem>
              We may share personal information when we do a business deal, or
              negotiate a business deal, involving the sale or transfer of all
              or a part of our business or assets. These deals can include any
              merger, financing, acquisition, or bankruptcy transaction or
              proceeding.
            </ListItem>
            <ListItem>
              We may share personal information for legal, protection, and
              safety purposes.
            </ListItem>
            <ListItem>We may share information to comply with laws.</ListItem>
            <ListItem>
              We may share information to respond to lawful requests and legal
              processes.
            </ListItem>
            <ListItem>
              We may share information to protect the rights and property of
              Flock Homes, Inc., our agents, customers, and others. This
              includes enforcing our agreements, policies, and terms of use.
            </ListItem>
            <ListItem>
              We may share information in an emergency. This includes protecting
              the safety of our employees and agents, our customers, or any
              person.
            </ListItem>
            <ListItem>
              We may share information with those who need it to do work for us.
            </ListItem>
          </List>
          <Paragraph>
            We may also share aggregated and/or anonymized data with others for
            their own uses.
          </Paragraph>
        </Section>

        <Section>
          <h4>INFORMATION CHOICES AND CHANGES</h4>
          <Paragraph>
            Our marketing emails tell you how to “opt-out.” If you opt out, we
            may still send you non-marketing emails. Non-marketing emails
            include emails about your accounts and our business dealings with
            you.
          </Paragraph>
          <Paragraph>
            You may send requests about personal information to our Contact
            Information below. You can request to change contact choices,
            opt-out of our sharing with others, and update your personal
            information.
          </Paragraph>
          <Paragraph>
            You can typically remove and reject cookies from our Site with your
            browser settings. Many browsers are set to accept cookies until you
            change your settings. If you remove or reject our cookies, it could
            affect how our Site works for you.
          </Paragraph>
          <Paragraph>
            <Bold>CONTACT INFORMATION.</Bold> We welcome your comments or
            questions about this privacy policy. You may also contact us at our
            mailing address:
          </Paragraph>
          <Paragraph>Flock Homes, Inc.</Paragraph>
          <Paragraph>250 Fillmore St, Suite 150</Paragraph>
          <Paragraph>Denver, Colorado 80206</Paragraph>
        </Section>
        <Section>
          <Bold>CHANGES TO THIS PRIVACY POLICY.</Bold> We may change this
          privacy policy. If we make any changes, we will change the Last
          Updated date above.
        </Section>
      </Container>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </PageLayout>
  )
}

export default PrivacyPolicy
