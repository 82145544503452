import React from 'react'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import { ThemeProvider, styled } from '@mui/material/styles'
import slugify from 'slugify'
import WebFont from '../webfontloader'
import flockTheme from '../flockTheme/flockTheme'
import useTracking, { TrackingConfig } from '../useTracking/useTracking'
import { getNodeText } from '../utils'

WebFont.load({
  google: {
    families: ['Inter&display=swap'],
  },
})

const StyledButton = styled(MuiButton)({
  fontFamily: 'Inter',
  fontSize: '1.125rem',
  textTransform: 'none',
  cursor: 'pointer',
})

type ButtonProps = MuiButtonProps & {
  trackingConfig?: TrackingConfig
}

const Button = (props: ButtonProps) => {
  const { onClick, trackingConfig } = props
  const { track } = useTracking()

  const onClickTracked = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onClick) {
      let trackingName = 'button-click'
      if (trackingConfig?.name) {
        trackingName = trackingConfig?.name
      } else if (props.children) {
        const nodeText = getNodeText(props.children)
        if (nodeText) {
          trackingName = slugify(nodeText.toLowerCase())
        }
      }
      track(trackingName, {
        type: 'button',
        ...trackingConfig?.properties,
      })
      onClick(event)
    }
  }

  return (
    <ThemeProvider theme={flockTheme}>
      <StyledButton {...props} onClick={onClickTracked} />
    </ThemeProvider>
  )
}

export default Button

Button.defaultProps = {
  trackingConfig: null,
}
