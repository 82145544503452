import { styled } from '@mui/material/styles'
import React from 'react'
import slugify from 'slugify'
import flockColors from '../flockColors/flockColors'
import useTracking, { TrackingConfig } from '../useTracking/useTracking'
import { getNodeText } from '../utils'

type LinkProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  target?: string
  trackingConfig?: TrackingConfig
  sx?: { [key: string]: any }
}

const StyledLink = styled('a')({
  color: flockColors.blue,
  opacity: '0.9',
  textDecoration: 'none',
  transition: 'opacity 0.5s ease',
  cursor: 'pointer',

  '&:hover': {
    opacity: '1',
  },
})

const OpacityLink = (props: LinkProps) => {
  const { onClick, trackingConfig } = props
  const { track } = useTracking()

  const onClickLink = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    let trackingName = 'link-click'
    if (trackingConfig?.name) {
      trackingName = trackingConfig?.name
    } else if (props.children) {
      const nodeText = getNodeText(props.children)
      if (nodeText) {
        trackingName = slugify(nodeText.toLowerCase())
      }
    }

    track(trackingName, {
      type: 'link',
      ...trackingConfig?.properties,
    })
    if (onClick) {
      onClick(event)
    }
  }

  return <StyledLink {...props} onClick={onClickLink} />
}

OpacityLink.defaultProps = {
  target: undefined,
  trackingConfig: null,
  sx: {},
}

export default OpacityLink
